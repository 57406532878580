import React, { useEffect, useRef, useState } from "react";
import './Dropdown.css';  // Assuming you create a separate CSS file for styles
import arrowUp from '../../Assets/arrow-up.png';
import arrowDown from '../../Assets/arrow-down.png';

const Dropdown = ({ field, form, options, placeholder, className }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { name, value } = field;
    const dropdownRef = useRef(null);
    const selectedItemRef = useRef(null);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        form.setFieldValue(name, option);
        setIsOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (isOpen && selectedItemRef.current) {
            selectedItemRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    }, [isOpen]);

    return (
        <div className={`dropdown ${className}`} ref={dropdownRef}>
            <div className="dropdown-header" onClick={handleToggle}>
                <span>{value || placeholder}</span>
                <span className="dropdown-arrow">{isOpen ?
                    <img className='dropdown-arrow-icon' src={arrowUp} alt='arrow up'/>
                    :
                    <img className='dropdown-arrow-icon' src={arrowDown} alt='arrow up'/>
                }</span>
            </div>
            {isOpen && (
                <ul className="dropdown-list">
                    {options.map((option, index) => (
                        <li
                            key={index}
                            ref={value === option ? selectedItemRef : null}
                            className={`dropdown-list-item ${value === option ? 'selected' : ''}`}
                            onClick={() => handleOptionClick(option)}
                        >
                            {option}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};
export default Dropdown;
