import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import ContactImg from '../Assets/contact-img-1.png';
import ContactForm from "../Contact Form/ContactForm";
import './Contact.css'




const Contact = () => {


    return (
        <>
            <section className='section'>
                <div className="contact-container container grid ">
                    <div className="contact-content-container grid">
                        <div className="contact-content">
                            <div className="section-title">Контакты
                            </div>
                            <p className="contact-content-description">
                                Адреса отделений и контактные номера банка. В этом разделе вы можете найти номера
                                телефонов и электронные адреса для связи с банком. А так же вы можете оставить нам свои
                                обращения или предложения.
                            </p>
                        </div>

                        <div className="contact-img-container">
                            <img src={ContactImg} alt="Planet" className='contact-content-img'/>
                        </div>
                    </div>

                    <br/>
                    <br/>

                    <div className="home-contact-container">

                        <div className='contact-details'>
                            <div className='contact-detail-group'>
                                <h2 className='contact-detail-title'>
                                    Адрес
                                </h2>
                                <p>
                                    <strong>
                                        Репрезентативный Офис: Glandore Office Building, Fitzwilliam Hall, 26
                                        Fitzwilliam Pl, Dublin 2, D02 T292, Ireland
                                    </strong>
                                    <br/>
                                    (Обслуживание физических лиц осуществляется по предварительной записи.)
                                    <br/>
                                    <strong>
                                        Центральный офис: Bonovo Road – Fomboni Island of Mohéli – Comoros Union
                                    </strong>
                                </p>
                            </div>

                            <div className='contact-detail-group'>
                                <h3 className='contact-detail-title'>
                                    Режим работы
                                </h3>
                                <p>
                                    <strong>
                                        Пн-Пт 09:00-18:00, Обед 13:00-14:00
                                    </strong>
                                </p>
                            </div>
                            <div className='contact-detail-group'>
                                <h2 className='contact-detail-title'>
                                    Электронная почта
                                </h2>
                                <a className='contact-detail-link' href='mailto:/info@mezhdunarodnaya-sberkassa.com'>
                                    info@mezhdunarodnaya-sberkassa.com
                                </a>
                            </div>
                            <div className='contact-detail-group'>
                                <h3 className='contact-detail-title'>
                                    Сайт
                                </h3>
                                <a className='contact-detail-link' href='https://mezhdunarodnaya-sberkassa.com/'
                                   target='_blank'>
                                    https://mezhdunarodnaya-sberkassa.com/
                                </a>
                            </div>
                            <div className='contact-detail-group'>
                                <h2 className='contact-detail-title'
                                >
                                    Телефон службы комплаенс
                                </h2>
                                <a href="tel:+35381888393" className='contact-detail-tell'>
                                    +353 81 888 393
                                </a>
                            </div>
                        </div>

                        <ContactForm/>
                    </div>
                </div>
            </section>

        </>

    );
};

export default Contact;
