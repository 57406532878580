import React, {useEffect} from 'react'
import {useNavigate} from "react-router-dom";
import MyBtn from "../ui/MyBtn/MyBtn";
import Safe from "../Assets/clients/safe.svg";
import USD from "../Assets/clients/usd.svg";
import Calendar from "../Assets/clients/calendar.svg";
import PieChart from "../Assets/clients/pie-chart.svg";
import Discount from "../Assets/clients/discount.svg";
import Money from "../Assets/clients/money.svg";
import Pin from "../Assets/clients/pin.svg";
import MasterCard from "../Assets/clients/Mastercard.svg";
import UnionPay from "../Assets/clients/Union Pay.svg";
import ApplePay from "../Assets/clients/ApplePay.svg";
import Discover from "../Assets/clients/Discover.svg";
import Express from "../Assets/clients/express.png";
import JCB from "../Assets/clients/JCB.svg";
import Visa from "../Assets/clients/Visa.svg";
import Gold from "../Assets/clients/gold.svg";
import FX from "../Assets/clients/fx.svg";
import './OtherServices.css';
import card2 from "../Assets/clients/gold.png";
import card3 from "../Assets/clients/gold2.png";
import card1 from "../Assets/clients/building.png";

function OtherServices() {
    const navigate = useNavigate();

    useEffect(() => {
        const href = window.location.href
        if (href.includes("#")) {
            const id = `${href.substring(href.indexOf("#") + 1)}`
            const anchor = document.getElementById(id)
            if (anchor) {
                anchor.scrollIntoView({behavior: "smooth"})
            }
        }
    }, [])

    return (
        <>
            <section className="section">
                <div className="others-container container grid">

                    <div className='others-main-content' id='#deposit-account'>
                        <div className='others-details'>

                            <h2 className='section-title'>
                                Депозитный счет
                            </h2>

                            <p className="others-description">
                                Депозиты за рубежом – это надежный, простой и законный способ снизить налоговое бремя
                                и сохранить капитал. Депозит за границей нужно рассматривать не только как возможностья
                                сохранить и приумножить средства, но и как выгодное вложениея.
                            </p>

                            <MyBtn title="Заказать Карту" large onClick={() => navigate("/register")}/>
                        </div>

                        <div className='others-main-image'>
                            <img src={Safe}
                                 alt='cards-section-img'
                                 className='others-main-img'
                            />
                        </div>
                    </div>

                    <div className="primary-others-card">

                        <h2 className='section-title-center'>
                            Преимущества и Выгода
                        </h2>
                        <br/>
                        <div className="primary--card-content">
                            <div className="primary--card">
                                <div className="card-title">Приватность
                                </div>
                                <div className="card-content">“Международная СберКасса не разглашает сведения о
                                    сумме и наличие счетов клиента государственным структурам ни при каких
                                    обстоятельствах.”
                                </div>
                            </div>

                            <div className="primary--card">
                                <div className="card-title">Безопасность и
                                    Стабильность
                                </div>
                                <div className="card-content">"Экономическая ситуация Банков-корреспондентов с
                                    которыми мы сотрудничаем более стабильна, чем в
                                    странах СНГ, что снижает сторонние риски по вкладам и депозитным счетам."
                                </div>
                            </div>

                            <div className="primary--card">
                                <div className="card-title">Доходность
                                </div>
                                <div className="card-content">"Мы предлагаем до 11% годовых новым Клиентам, а также
                                    до 12% годовых
                                    Клиентам с историей в нашем Банке более двух лет."
                                </div>
                            </div>
                        </div>

                        <div className="primary-others-btn">
                            <MyBtn
                                title="Открыть Счет" large
                                onClick={() => navigate("/register")}
                            />
                        </div>
                    </div>


                    <div className="secondary-others-cards">
                        <h1 className='section-title-center'>
                            Международная СберКасса
                            готова предоставить следующие условия
                        </h1>
                        <br/>
                        <div className="secondary-cards-content">
                            <article className='secondary-card'>
                                <h3 className='secondary-card-title'>
                                    Минимальная сумма:
                                </h3>
                                <div className='secondary-card-sub'>
                                    <p className='secondary-card-descriptin'>
                                        10 000 EUR, USD (другие валюты по запросу)
                                    </p>
                                    <img src={USD} alt='others-vector' className='secondary-card-icon'/>
                                </div>
                            </article>

                            <article className='secondary-card'>
                                <h3 className='secondary-card-title'>
                                    Срок размещения:
                                </h3>
                                <div className='secondary-card-sub'>
                                    <p className='secondary-card-descriptin'>
                                        от 3 месяцев до 5 лет
                                    </p>
                                    <img src={Calendar} alt='others-vector' className='secondary-card-icon'/>
                                </div>
                            </article>

                            <article className='secondary-card'>
                                <h3 className='secondary-card-title'>
                                    Выплата процентов:
                                </h3>
                                <div className='secondary-card-sub'>
                                    <p className='secondary-card-descriptin'>
                                        раз в 1, 3, 6, 12 месяцев
                                    </p>
                                    <img src={PieChart} alt='others-vector' className='secondary-card-icon'/>
                                </div>
                            </article>
                        </div>
                        <div className='secondary-cards-sub-container'>
                            <article className='secondary-card'>
                                <h3 className='secondary-card-title'>
                                    Процент выплаты по депозитам для Клиентов без истории:
                                </h3>
                                <div className='secondary-card-sub'>
                                    <p className='secondary-card-descriptin'>
                                        10% EUR; 11% USD <br/>
                                        Сроки выплаты 1-3 месяца: 9,5% EUR; 10,5% USD<br/>
                                        Сроки выплаты 6-12 месяцев: 10% EUR; 11% USD
                                    </p>
                                    <img src={Discount} alt='others-vector' className='secondary-card-icon'/>
                                </div>
                            </article>

                            <article className='secondary-card'>
                                <h3 className='secondary-card-title'>
                                    Процент выплаты по депозитам для Клиентов с историей более одного года:
                                </h3>
                                <div className='secondary-card-sub'>
                                    <p className='secondary-card-descriptin'>
                                        11% EUR; 12% USD <br/>
                                        Сроки выплаты 1-3 месяца: 10,5% EUR; 11,5% USD<br/>
                                        Сроки выплаты 6-12 месяцев: 11% EUR; 12% USD
                                    </p>
                                    <img src={Discount} alt='others-vector' className='secondary-card-icon'/>
                                </div>
                            </article>

                        </div>
                    </div>

                    <div className='others-area' id="cashing-checks">
                        <div className="area-image">
                            <img src={Money} alt="Money" className='area-img'/>
                        </div>
                        <div className='area-content'>
                            <h1 className='section-title area-tittle'>
                                Обналичивание
                                банковских чеков
                            </h1>
                            <p className='area-description'>
                                Международная СберКасса предлагает услугу обналичивания банĸовсĸих чеĸов. Условия
                                обналичивания: 100 EUR, USD + 2% от суммы самого чека.
                                Процесс обналичивания чеков зависит от Банка-эмитента.
                                Среднее время ожидания варьируется от 7-ми до 14-ти банковских дней.
                            </p>
                        </div>
                    </div>

                    <div className='others-area' id="international-acquiring">
                        <div className='area-content'>
                            <h1 className='section-title area-tittle'>
                                Международный
                                Эквайринг
                            </h1>
                            <p className='area-description'>
                                Прием международных платежей для онлайн бизнеса. Вы сможете принимать платежи в 150+
                                странах мира и выводить заработанные средства на счет компании или ваши персональные
                                реквизиты, а также на криптокошелек.
                                Условия и тарифы уточняются у вашего персонального Менеджера.
                            </p>
                        </div>
                        <div className="area-image">
                            <img src={Pin} alt="Money" className='area-img'/>
                        </div>
                    </div>

                    <div className="other-slot-container" >
                        <div className="other-slot">
                            <img src={Visa} alt="brand" className='slot-icon'/>
                        </div>
                        <div className="other-slot">
                            <img src={MasterCard} alt="brand" className='slot-icon'/>
                        </div>
                        <div className="other-slot">
                            <img src={UnionPay} alt="brand" className='slot-icon'/>
                        </div>
                        <div className="other-slot">
                            <img src={ApplePay} alt="brand" className='slot-icon'/>
                        </div>
                        <div className="other-slot">
                            <img src={Discover} alt="brand" className='slot-icon'/>
                        </div>
                        <div className="other-slot">
                            <img src={Express} alt="brand" className='slot-icon'/>
                        </div>
                        <div className="other-slot">
                            <img src={JCB} alt="brand" className='slot-icon'/>
                        </div>
                    </div>

                    <div className="quote-container">
                        <p className="quote-text">
                            "Мы успешно справляемся с нестандартными задачами и готовы
                            предложить гарантию лучших условий по сравнению с нашими конкурентами."
                        </p>
                    </div>

                    <div className='others-area' id="operations-metals">
                        <div className="area-image">
                            <img src={Gold} alt="Money" className='area-img'/>
                        </div>
                        <div className='area-content'>
                            <h1 className='section-title area-tittle'>
                                Операции с
                                Драгоценными Металлами
                            </h1>
                            <p className='area-description'>
                                Приобретение золотых слитков - классический способ вложения в золото для сохранения
                                капитала. Каждый год в мире покупается около тысячи тонн золотых слитков. Исследования
                                показывают, что оптимальным долгосрочным вложением в золото является 2-10% от общей
                                стоимости портфеля, в зависимости от уровня риска, что способно обеспечить защиту и рост
                                доходности инвестиционного портфеля.
                            </p>
                        </div>
                    </div>

                    <div className='tertiary-others-cards'>
                        <div className="tertiary-others-card">
                            <img className='tertiary-others-img' src={card1} alt="Image 1"/>
                            <div className="card-text">
                                Международная СберКасса предлагает возможность приобретения золотых слитков напрямую с
                                немецкого аффинажного завода C.HAFNER GmbH + Co. KG.
                            </div>
                        </div>

                        <div className="tertiary-others-card">
                            <img className='tertiary-others-img' src={card2} alt="Image 1"/>
                            <div className="card-text">
                                Мы предоставляем возможность приобрести золото 999,9 пробы в слитках стандарта LBMA
                                (Лондонская Биржа драгоценных металлов) с возможностью хранения в банке..
                            </div>
                        </div>

                        <div className="tertiary-others-card">
                            <img className='tertiary-others-img' src={card3} alt="Image 1"/>
                            <div className="card-text">
                                При покупке физического золота с доставкой, Вы можете выбрать предпочтительный вес
                                слитка – 5, 10, 20, 50, 100, 250, 500 грамм, 1 унцию, или купить...
                            </div>
                        </div>
                    </div>


                    <div className='others-area' id="sberkassa-fx">
                        <div className='area-content'>
                            <h1 className='section-title area-tittle'>
                                Международная <br/>
                                СберКасса FX
                            </h1>
                            <p className='area-description'>
                                Прием международных платежей для онлайн бизнеса. Вы сможете принимать платежи в 150+
                                странах мира и выводить заработанные средства на счет компании или ваши персональные
                                реквизиты, а также на криптокошелек.
                            </p>
                        </div>
                        <div className="area-image">
                            <img src={FX} alt="Money" className='area-img'/>
                        </div>
                    </div>

                    <h1 className="section-title">
                        Мы предлагаем своим Клиентам возможность торговли на рынке Forex в условиях
                        реального спроса и предложения:
                    </h1>

                    <div className="other-container">
                        <div className="other-row">
                            <div className="other-no">01</div>
                            <div className="other-text">
                                Торговля валютами металлами и нефтью
                            </div>
                        </div>

                        <div className="other-row">
                            <div className="other-no">02</div>
                            <div className="other-text">
                                Ликвидность от ведущих банков-маркетмейкеров
                            </div>
                        </div>

                        <div className="other-row">
                            <div className="other-no">03</div>
                            <div className="other-text">
                                Моментальное исполнение ордеров с помощьютехнологий STP
                            </div>
                        </div>

                        <div className="other-row">
                            <div className="other-no">04</div>
                            <div className="other-text">
                                Узкие спреды и котировки от основных поставщиков ликвидности
                            </div>
                        </div>

                        <div className="other-row">
                            <div className="other-no">05</div>
                            <div className="other-text">
                                Поддержка скальпинга
                            </div>
                        </div>

                        <div className="other-row">
                            <div className="other-no">06</div>
                            <div className="other-text">
                                Отсутствие ограничений по дистанции выставления стоп и лимит ордеров
                            </div>
                        </div>

                        <div className="other-row">
                            <div className="other-no">07</div>
                            <div className="other-text">
                                Открытие счета, зачисление и вывод средств.
                                После внутренних проверок на одобрение открытия счета, зачисление или вывод средств
                                производятся банковским платежом на сновании соответствующего распоряжения.
                            </div>
                        </div>

                        <div className="other-row">
                            <div className="other-no">08</div>
                            <div className="other-text">
                                Узкие спреды и котировки от основных поставщиков ликвидности
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default OtherServices
