import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
    error: null,
    user: {
        verificationsList: {
            emailVerifyEnabled: true,
            twoFaVerifyEnabled: false,
            phoneVerifyEnabled: false,
        },
        flags: {
            kycStatus: null
        },
        userToken: "",
        userData: {
            address: "",
            callPhone: "",
            city: "",
            clientId: "",
            companyName: "",
            country: "",
            email: "",
            fax: "",
            firstName: "",
            lastName: "",
            postCode: "",
            phoneNumber: "",
            salutation: "",
            stateOrProvince: "",
            accountType: "personal",
        },
        kycList: [],
        phoneList: [],
    },
    registrationWasSuccess: null,
    accountsList: [],
    walletsList: [],
    isLoading: false,
    disabled: true,
    tokenIsValid: true
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserData(state, action) {
            state.user = action.payload;
            state.error = null;
        },
        userAuthorizationError(state, action) {
            state.error = action.payload;
        },
        signOut(state) {
            state.user.userToken = "";
            state.user.userData = {
                address: null,
                callPhone: null,
                city: null,
                clientId: null,
                companyName: null,
                country: null,
                email: null,
                fax: null,
                firstName: null,
                lastName: null,
                phoneNumber: null,
                postCode: null,
                salutation: null,
                stateOrProvince: null,
                accountType: 'personal'
            };
            state.error = null;
            state.isLoading = false;
            state.disabled = false;
            state.accountsList = [];
            state.walletsList = [];
            state.tokenIsValid = true;
            localStorage.clear();
        },
        setAccountsList(state, action) {
            state.accountsList = action.payload;
        },
        setWalletsList(state, action) {
            state.walletsList = action.payload;
        },
        setRegistrationWasSuccess(state, action) {
            state.registrationWasSuccess = action.payload;
        },
        setUserEmail(state, action) {
            state.user.userData.email = action.payload;
        },
        setKycList(state, action) {
            state.user.kycList = action.payload;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setDisabled(state, action) {
            state.disabled = action.payload;
        },
        setPhoneList(state, action) {
            state.user.phoneList = action.payload;
        },
        setIsSmsCode(state, action) {
            state.user.verificationsList.phoneVerifyEnabled = action.payload;
        },
        setIs2faCode(state, action) {
            state.user.verificationsList.twoFaVerifyEnabled = action.payload;
        },
        setNewEmail(state, action) {
            state.user.userData.email = action.payload;
        },
        setTokenStatus(state, action) {
            state.tokenIsValid = action.payload;
        }
    }
});

export const {
    setAccountsList,
    setWalletsList,
    setIsLoading,
    setDisabled,
    setPhoneList,
    setNewEmail,
    setTokenStatus,
} = userSlice.actions;

export default userSlice.reducer;
