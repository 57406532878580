import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {createNewPasswordThunk} from '../../../store/reducers/actions'; // Adjust the import path as necessary
import './Settings.css';
import verified from '../../Assets/icons/verified.png';
import {useAppSelector} from "../../../store/store";
import MyInput from "../MyInput/MyInput";
import {Formik} from "formik";
import CustomModal from "../customModal/customModal";
import MyBtn from "../MyBtn/MyBtn";

function Settings() {

    const user = useAppSelector(store => store.userReducer.user.userData)
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false)
    let userName;
    if (user.firstName !== null && user.firstName !== undefined) {
        userName = `${user.firstName} ${user.lastName}`;
    } else {
        userName = user.companyName;
    }
    let userType;
    if (user.accountType === 'personal') {

        userType = 'Персональный'
    } else userType = 'Бизнес'

    const dispatch = useDispatch();
    const {userToken} = useAppSelector(store => store.userReducer.user)

    const kycStatus = useAppSelector(state => state.userReducer.user.flags.kycStatus)

    const kycIsValid = () => {
        if (!kycStatus) {
            return false
        } else if (kycStatus === "New") {
            return false
        } else if (kycStatus === "Rejected") {
            return false
        } else {
            return true
        }
    }
    return (
        <div>
            <CustomModal isModalOpen={isPopupOpen} setIsModalOpen={setIsPopupOpen}>
            <div className="title">
                Успешно!
            </div>
            <div className="modal-section">
                Ваш пароль был успешно обновлен.
            </div>
            <div className="modal-section">
                <MyBtn medium title={"Ok"} onClick={() => setIsPopupOpen(false)}/>
            </div>
        </CustomModal>
            <CustomModal isModalOpen={isErrorPopupOpen} setIsModalOpen={setIsErrorPopupOpen}>
        <div className="title">
            Ошибка!
        </div>
        <div className="modal-section">
            Пожалуйста, проверьте правильность введенных данных и попробуйте снова.
        </div>
        <div className="modal-section">
            <MyBtn medium title={"Ok"} onClick={() => setIsErrorPopupOpen(false)}/>
        </div>
    </CustomModal>
            <section className='password_change-section' >

            <h2 className='section-subtitle  ' >
                Настройки аккаунта
            </h2>
            <br/>
            <div className='password_change-container '>

                <div className='password_change-content' >
                    <div className='change_password-table'>

                        <div className="table-container-one">
                            <div className="table-header-one">
                                <div className="table-cell-one">Владелец Аккаунта</div>
                                <div className="table-cell-one">идентификационный номер</div>
                                <div className="table-cell-one">Тип аккаунта</div>
                            </div>
                            <div className="table-row-one">
                                <div className="table-cell-one" data-label="Владелец Аккаунта">{userName}</div>
                                <div className="table-cell-one" data-label="идентификационный номер">{user.clientId}</div>
                                <div className="table-cell-one" data-label="Тип аккаунта">{userType}</div>
                            </div>
                        </div>

                    </div>
                    <h2 className='password_change-title '>Смена пароля</h2>
                    <p>Новый пароль должен содержать не менее 8 символов, в том числе: 1 строчную, 1 заглавную и 1
                        цифру.
                    </p>
                    <Formik
                        initialValues={{
                            oldPassword: '',
                            newPassword: '',
                            confirmPassword: ''
                        }}
                        onSubmit={(values, actions) => {
                            dispatch(createNewPasswordThunk(userToken, values))
                                .then((res) => {
                                    if (res.data === "Done") {
                                        actions.resetForm();
                                        setIsPopupOpen(true)
                                    }
                                })
                                .catch(error => {
                                    setIsErrorPopupOpen(true)
                                })
                                .finally(() => {
                                    actions.setSubmitting(false);
                                });

                        }}
                    >
                        {formikProps => (
                            <form onSubmit={formikProps.handleSubmit} className='change_password-form'>
                                <div className="password-input-wrapper">
                                    <label className='change_password-label'>
                                        <span className='icon_1'>*</span> Ваш пароль
                                    </label>
                                    <div className="input_container">
                                        <MyInput id="oldPassword"
                                                 name="oldPassword"
                                                 password
                                                 required
                                                 placeholder="Пароль"
                                                 value={formikProps.values.oldPassword}
                                                 onChange={formikProps.handleChange}
                                        />
                                        {/* <button type="button" onClick={() => togglePasswordVisibility('current')} className="toggle-password-button">
                                    {showPassword.current ?
                                        <img src={no_view} alt='view-icon' />
                                        :
                                        <img src={view} alt='view-icon' />
                                    }
                                </button>*/}
                                    </div>
                                </div>
                                <div className="password-input-wrapper">
                                    <label className='change_password-label'>
                                        <span className='icon_1'>*</span> Новый пароль
                                    </label>
                                    <div className='input_container'>
                                        <MyInput id="newPassword"
                                                 name="newPassword"
                                                 password
                                                 required
                                                 placeholder="Пароль"
                                                 value={formikProps.values.newPassword}
                                                 onChange={formikProps.handleChange}

                                        />
                                        {/*<button type="button" onClick={() => togglePasswordVisibility('new')} className="toggle-password-button">
                                    {showPassword.new ?
                                        <img src={no_view} alt='view-icon' />
                                        :
                                        <img src={view} alt='view-icon' />
                                    }
                                </button>*/}
                                    </div>
                                </div>
                                <div className="password-input-wrapper">
                                    <label className='change_password-label'>
                                        <span className='icon_1'>*</span> Подтвердите свой новый пароль
                                    </label>
                                    <div className='input_container'>
                                        <MyInput id="confirmPassword"
                                                 name="confirmPassword"
                                                 password
                                                 required
                                                 placeholder="Пароль"
                                                 value={formikProps.values.confirmPassword}
                                                 onChange={formikProps.handleChange}
                                        />
                                        {/* <button type="button" onClick={() => togglePasswordVisibility('confirm')} className="toggle-password-button">
                                    {showPassword.confirm ?
                                        <img src={no_view} alt='view-icon' className='toggle-password-icon' />
                                        :
                                        <img src={view} alt='view-icon' />
                                    }
                                </button>*/}
                                    </div>
                                </div>
                                <button className='change_password-button secondary-button' type='submit'>Изменить</button>
                            </form>
                        )}
                    </Formik>
                    <div className='bottom'>
                        {/* <h2 className='change_password-title'>Электронная почта</h2>
                        <div className='bottom_content'>
                            <div className="change_password-table">
                                <table className='table_two'>
                                    <thead>
                                    <tr>
                                        <th className='table_header'>Почта</th>
                                        <th className='table_header'>Статус</th>
                                        <th className='table_header'>Приоритет</th>
                                        <th className='table_header'>действие</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>bub@mail.ru</td>
                                        <td>Верифицирована</td>
                                        <td>Основная</td>
                                        <td>
                                            <button className='button_three'>
                                                <img src={trash} alt='trash-icon' className='table-trash_icon' /> Delete
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <button className='change_password-button button_two'>+ Добавить новую</button>
                        </div>*/}
                    </div>
                </div>
                {
                    !kycIsValid()
                        ? <></> :
                        <div className='password_change-confirm'>
                            <h2 className='password_change-title'>Ваш аккаунт верифицирован</h2>
                            <img src={verified} className='verified-vector'/>
                        </div>
                }
            </div>
        </section>
        </div>
    );
}

export default Settings;
