import React, { useEffect, useState } from 'react';
import s from "./MyInput.module.css";
import PropTypes from "prop-types";


const MyInput = (props) => {
    const [inputType, setInputType] = useState("text");

    useEffect(() => {
        if (props.password) {
            setInputType("password");
        }
    }, []);

    const changeInputType = (e) => {
        e.preventDefault();
        setInputType(inputType === "password" ? "text" : "password");
    };

    return (
        <div className={s.inputWrapper} style={{ width: props.width }}>
            <input
                type={inputType}
                readOnly
                onFocus={(e) => e.target.readOnly = false}
                className={`${s.my_input} ${props.isError && props.touched ? s.error_input : ''}`}
                {...props}
            />
            <div className={props.password ? s.view : s.hidden}>
                <a href="#" className={inputType === "text" ? s.passwordView : s.passwordControl} onClick={changeInputType}>
                </a>
            </div>
        </div>
    );
};

MyInput.propTypes = {
    password: PropTypes.bool,
    isError: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    touched: PropTypes.bool,
    width: PropTypes.string,
    ref: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
    disabled: PropTypes.bool
};


export default MyInput;
