import React, { useState } from 'react';

const TransactionList = ({ transactions }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 8;

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp * 1000); // Convert to milliseconds
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return date.toLocaleDateString('ru-RU', options); // Adjust locale as needed
    };

    const getStatusText = (status) => {
        const statusTexts = {
            'New': 'В ожидании',
            'Denied': 'Отклонено',
            'Approved': 'Одобрено',
        };
        return statusTexts[status] || status;
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentTransactions = transactions.slice(startIndex, startIndex + itemsPerPage);



    return (
        <>

            <div className="primary-table-container">
                <table>
                    <caption >
                        <h3 className='section-subtitle'>
                            Последние Транзакции
                        </h3>
                    </caption>
                    <thead>
                    <tr>
                        <th scope="col">
                            Получатель
                        </th>
                        <th scope="col">
                            Сумма
                        </th>
                        <th scope="col">
                            Статус
                        </th>
                        <th scope="col">
                            Дата
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {currentTransactions.length > 0 ? (
                        currentTransactions.map((transaction) => (
                            <tr key={transaction.key}>
                                <td data-label="Получатель">
                                    {transaction.recipient ?? transaction.toAccount}
                                </td>
                                <td data-label="Сумма">
                                    {transaction.amount} {transaction.currency}
                                </td>
                                <td data-label="Статус">
                                    {getStatusText(transaction.transactionStatus)}
                                </td>
                                <td data-label="Дата">
                                    {formatTimestamp(transaction.created)}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td
                                data-label=""
                            >
                                Нет данных
                            </td>
                        </tr>
                    )}
                    </tbody>

                    <div className="pagination">
                        <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                            Назад
                        </button>

                        <button
                            onClick={handleNextPage}
                            disabled={startIndex + itemsPerPage >= transactions.length}
                        >
                            Далее
                        </button>
                    </div>
                </table>
            </div>


        </>
    );
};

export default TransactionList;