import React, { useState, useEffect, useRef } from 'react';
import { useFormikContext, useField } from 'formik';
import './MyDropdown.css';

const MyDropdown = (props) => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(props.name);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setIsDropdownVisible(!isDropdownVisible);
    };

    const handleSelectItem = (item) => {
        setFieldValue(props.name, item);
        setIsDropdownVisible(false);
    };

    return (
        <div
            ref={dropdownRef}
            onClick={toggleDropdown}
            className='my-dropdown-container'
        >
            <div className="my-dropdown-header">
                <input
                    className={`my-dropdown-input ${meta.touched && meta.error ? 'error' : ''}`}
                    id={props.id}
                    name={props.name}
                    type="text"
                    value={field.value}
                    placeholder={props.placeholder}
                    readOnly
                />
                <div className="my-dropdown-icons">
                    {isDropdownVisible ?
                        <div>˄</div>
                        :
                        <span>˅</span>
                    }
                </div>
            </div>
            {isDropdownVisible && (
                <ul className="my-dropdown-list">
                    {props.items.map((option, index) => (
                        <li
                            className={`my-dropdown-list-item ${field.value === option ? 'selected' : ''}`}
                            key={index}
                            onClick={() => handleSelectItem(option)}
                        >
                            {option}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default MyDropdown;
