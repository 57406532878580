import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import hunters from '../Assets/teriff&Services/hunters.png';
import rick from '../Assets/teriff&Services/rick.png';
import shane from '../Assets/teriff&Services/shane.png';
import money from '../Assets/teriff&Services/check.png';
import map from '../Assets/teriff&Services/map.png';
import gold from '../Assets/teriff&Services/gold.png';
import online from '../Assets/teriff&Services/online.png';
import arrow from '../Assets/teriff&Services/arrow.svg';
import arrowFront from '../Assets/teriff&Services/arrow-front.svg';
import layerImage from '../Assets/teriff&Services/layerImage.png'


import './Slider.css';

const AutoSlider = () => {
    const images = [hunters, rick, shane, layerImage, money, map, gold, online];
    const texts = [
        'Отĸрытие Корпоративного Счета с персональным SWIFT/ SEPA IBAN',
        'Отĸрытие Корпоративного Счета с Общим SWIFT/SEPA IBAN',
        'Отĸрытие Индивидуального Счета с персональным SWIFT/SEPA IBAN',
        'Отĸрытие Индивидуального Счета с Общим SWIFT/SEPA IBAN',
        'Обналичивание Банĸовсĸих Чеĸов',
        '3% Международный Эĸвайринг',
        'Операции с Драгоценными Металлами',
        'Онлайн Банĸинг СберКредит FX'
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        autoplay: true,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplaySpeed: 3000,
        prevArrow: <img src={arrow} alt="Arrow" className="arrow-svg" />,
        nextArrow: <img src={arrowFront} alt="Arrow" className="arrow-svg" />,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: false,
                    className: 'gap-slides'  // Add this line to apply a gap class
                }
            },
        ]
    };

    return (
        <div className="slider-container">
            <Slider {...settings}>
                {images.map((image, index) => (
                    <div key={index} className="sliderContent">
                        <div className="sliderCard">
                            <img src={image} alt='slider content'/>
                        </div>
                        <div className="sliderTextContainer">
                            <div className="sliderText">{texts[index]}</div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default AutoSlider;
