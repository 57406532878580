import React, {useState} from 'react';
import './SignIn.css';
import MyInput from "../../../ui/MyInput/MyInput";
import MyBtn from "../../../ui/MyBtn/MyBtn";
import {getLoginList} from "../../../../store/reducers/actions";
import {Formik} from "formik";
import {useAppDispatch} from "../../../../store/store";
import CustomModal from "../../../ui/customModal/customModal";

function SignIn(props) {

    const dispatch = useAppDispatch()
    const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false)


    return (
        <>
            <CustomModal isModalOpen={isErrorPopupOpen} setIsModalOpen={setIsErrorPopupOpen}>
                <div className="title">
                    Ошибка!</div>
                <div className="modal-section">
                    Пожалуйста, проверьте правильность введенных данных и попробуйте снова.
                </div>
                <div className="modal-section">
                <MyBtn medium title={"Ok"} onClick={() => {setIsErrorPopupOpen(false)}}/>
                </div>

            </CustomModal>

            <Formik
                            initialValues={{
                                email: '',
                                password: ''
                            }}
                            onSubmit={(values, actions) => {
                                props.setLoginData(
                                    {...props.dataForSend,
                                        email: values.email,
                                        password: values.password,
                                    }
                                );
                                dispatch(getLoginList(values))
                                    .then(() => {
                                        console.log('Request successful!');
                                        props.setCurrent(1);
                                    })
                                    .catch(error => {
                                        console.error('Login error:', error);
                                        setIsErrorPopupOpen(true)
                                    })
                                    .finally(() => {
                                        actions.setSubmitting(false);
                                    });

                            }}
                        >
                            {formikProps => (
                                <form onSubmit={formikProps.handleSubmit}>
                            <div className="title">
                                Рады Вас снова приветствовать!
                            </div>
                                <MyInput
                                    id="email"
                                    name="email"
                                    placeholder="E-mail"
                                    value={formikProps.values.email}
                                    onChange={formikProps.handleChange}
                                />

                                <MyInput id="password"
                                         name="password"
                                         password
                                         placeholder="Пароль"
                                         value={formikProps.values.password}
                                         onChange={formikProps.handleChange}
                                />

                                <a href="/forgot_password" className="span_password">Восстановление доступа</a>

                            <div className="btn_block">
                                <MyBtn title={"Войти"} type={"submit"} style={{height: '50px', width: '200px'}}/>
                            </div>

                        <div className="auth-question">
                                Нет аккаунта СберКассы?
                            <a href="/register"  className="auth-question-link">Зарегистрироваться</a>
                        </div>


                    </form>
                    )}
                </Formik>
        </>
    )
}

export default SignIn