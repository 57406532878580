import React from 'react';
import './MyBtn.css';

const MyBtn = ({ type, title, icon, large, medium, small, buttonType, localDisable, onClick, style, ...props }) => {
    const disabled = false; 

   
    let className = 'my_btn';
    if (large) className += ' large';
    if (medium) className += ' medium';
    if (small) className += ' small';
    if (disabled || localDisable) className += ' disabled';
    if (buttonType === 'confirm') className += ' confirm';
    if (buttonType === 'error') className += ' error';

    return (
        <button
            className={className}
            disabled={disabled ? disabled : localDisable}
            onClick={onClick}
            style={style}
            {...props}
        >
            {icon && <img className="img" src={icon} alt="" />}
            {title}
        </button>
    );
};

export default MyBtn;
