import React, {useEffect, useState} from 'react';
import { Formik, Form, Field } from 'formik';
import './bankTransfer.css';
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {setDisabled} from "../../../store/reducers/UserSlice";
import {SendTransferThunk} from "../../../store/reducers/actions";
import CustomModal from "../customModal/customModal";
import MyBtn from "../MyBtn/MyBtn";
import Dropdown from "../Dropdown/Dropdown";

const IntraTransferForm = () => {
    const dispatch = useAppDispatch()
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const {userToken} = useAppSelector(store => store.userReducer.user)
    const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false)

    const userAccounts = useAppSelector(store => store.userReducer.accountsList)
    const [minDate, setMinDate] = useState('');

    useEffect(() => {
        const today = new Date();
        const minDateValue = today.toISOString().split('T')[0]; // Format: YYYY-MM-DD
        setMinDate(minDateValue);
    }, []);


    const handleOkClick = () => {
        setIsPopupOpen(false);
        window.location.href = '/kabinet';  };

    const currencies = [
        'USD',
        'EUR',
        'GBP',
        'CHF',
        'RUB',
        'TRY',
        'AED',
        'CNY',
        'AUD',
        'CZK',
        'PLN',
        'CAD',
        'USDT'
    ];
    return (

        <div>

            <CustomModal isModalOpen={isPopupOpen} setIsModalOpen={setIsPopupOpen}>
                <div className="title">
                    Успешно!</div>
                <div className="modal-section">
                    Ваша заявка на перевод была отправлена.
                </div>
                <div className="modal-section">
                    <MyBtn medium title={"Ok"} onClick={() => {handleOkClick()}}/>
                </div>
            </CustomModal>

            <CustomModal isModalOpen={isErrorPopupOpen} setIsModalOpen={setIsErrorPopupOpen}>
                <div className="title">
                    Ошибка!</div>
                <div className="modal-section">
                    Пожалуйста, проверьте правильность введенных данных и попробуйте снова.
                </div>
                <div className="modal-section">
                    <MyBtn medium title={"Ok"} onClick={() => {setIsErrorPopupOpen(false)}}/>
                </div>
            </CustomModal>

            <h3 className='transfer-title section-subtitle '>Перевод на получателя СберКассы</h3>
            <br/>
            <div className="bank-transfer-main-container">
                <Formik
                    initialValues={{
                        accNumberFrom: '',
                        fullName: '',
                        accNumberTo: '',
                        email: '',
                        transferDate: '',
                        amount: '',
                        transferCurrency: 'USD',
                        reference: '',
                        templateName: '',
                        type: 'internal'
                    }}
                    onSubmit={(values, actions) => {
                        dispatch(setDisabled(true))

                        dispatch(SendTransferThunk(userToken, values))
                            .then((res) => {
                                if (res.data === 'Created') {
                                    console.log('created')
                                    setIsPopupOpen(true)
                                } else {
                                    console.log('error')
                                    setIsErrorPopupOpen(true)
                                }
                            })
                            .catch((e) => {
                                console.log('error')
                                setIsErrorPopupOpen(true)
                            })
                            .finally(() => {
                                localStorage.removeItem("transfer-form")
                                dispatch(setDisabled(false))
                            })

                    }}
                >
                    <Form className="transfer-form">
                        <div className="transfer-form-box">
                            <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                <span className="transfer-form-icon">* </span> Счет:
                            </label>
                            <Field name="fromAccount">
                                {({ field, form }) => (
                                    <Dropdown
                                        field={field}
                                        form={form}
                                        options={userAccounts.map(account => account.number)}
                                        placeholder=""
                                    />
                                )}
                            </Field>
                        </div>

                        <h3 className="transfer-form-subtitle">
                            Детали Получателя
                        </h3>
                        <div className="transfer-form-box">
                            <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                <span className="transfer-form-icon">* </span> Метод перевода:
                            </label>
                            <Field name="paymentMethod">
                                {({ field, form }) => (
                                    <Dropdown
                                        field={field}
                                        form={form}
                                        options={['Счет СберКассы']}
                                        placeholder=""
                                    />
                                )}
                            </Field>
                        </div>
                        <div className='transfer-form-group'>
                            <div className="transfer-form-box">
                                <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                    <span className="transfer-form-icon">* </span>Номер счета
                                </label>
                                <Field type="text"
                                       name="accNumberTo"
                                       id="accNumberTo"
                                       placeholder=""
                                       required
                                       className="transfer-form-input"
                                />
                            </div>
                            <div className="transfer-form-box">
                                <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                    <span className="transfer-form-icon">* </span>Электронная Почта:
                                </label>
                                <Field
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder=""
                                    className="transfer-form-input"
                                />
                            </div>
                        </div>
                        {/*     <div className='field-wrapper'>
                                    <label htmlFor="paymentDate"><span className="required">* </span> Дата Перевода: </label>
                                    <Field type="date" name="paymentDate" id="paymentDate" placeholder="" min={minDate}  required />
                                </div>         
                         */}
                        <h3 className="transfer-form-subtitle">
                            Детали перевода
                        </h3>

                        <div className='transfer-form-group'>
                            <div className="transfer-form-box">
                                <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                    <span className="transfer-form-icon">* </span>Сумма:
                                </label>
                                <Field type="text" name="amount" id="amount" placeholder="" required
                                       className="transfer-form-input"
                                />
                            </div>
                            <div className="transfer-form-box">
                                <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                    <span className="transfer-form-icon">* </span>Валюта:
                                </label>

                                <Field name="paymentRegions">
                                    {({ field, form }) => (
                                        <Dropdown
                                            field={field}
                                            form={form}
                                            options={currencies}
                                            placeholder=""
                                        />
                                    )}
                                </Field>
                            </div>
                        </div>

                        <div className="transfer-form-box">
                            <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                <span className="transfer-form-icon">* </span>Причина:
                            </label>
                            <Field as="textarea"
                                   name="reference"
                                   id="reference"
                                   placeholder="Введите причину перевода"
                                   rows="4"
                                   className='transfer-form-textarea'
                            />
                        </div>
                        {/* <div className='translation-details-container'>
                            <h2>Сохранить детали перевода как шаблон</h2>
                            <div className="field-wrapper">
                                <label htmlFor="templateName">Наименование Шаблона: </label>
                                <Field type="text" name="templateName" id="templateName" placeholder="" />
                            </div>
                        </div>*/}

                            <div className='transfer-form-bottom'>
                                <label className='transfer-form-label'>
                                    <span className="transfer-form-icon">* </span> Обязательные поля
                                </label>
                                <button className='secondary-button' type="submit">Подтвердить</button>
                            </div>
                    </Form>
                </Formik>
            </div>
        </div>


);
};

export default IntraTransferForm;
