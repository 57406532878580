import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider, useNavigate} from 'react-router-dom'
import Layout from './Layout';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import {Provider} from "react-redux";
import store, {useAppSelector} from "./store/store";
import SignInSteps from "./components/Authentication/SignIn/SignInSteps";
import RegistrationSteps from "./components/Authentication/Registration/RegistrationSteps";
import ForgotPassword from "./components/Authentication/ForgotPassword/ForgotPassword";
import AboutBank from './components/AboutBank/aboutBank';
import CorporateClients from "./components/CorporateClients/CorporateClients";
import PhysicalClients from "./components/CorporateClients/PhysicalClients/PhysicalClients";
import OtherServices from "./components/OtherServices/OtherServices";
import Services from './components/teriffAndServices/Services.jsx';
import CryptoOperations from './components/CryptoCurrency/CryptoOperations.jsx';
import Accounts from './components/ui/currencyAccounts/currencyAccounts';
import SideMenuLayout from './sideMenuLayout';
import Cards from "./components/Cards/Cards";
import Contact from './components/Contact/Contact';
import './main.css';

function PrivateRoute({ element, ...rest }) {
    const navigate = useNavigate();
    const userToken = useAppSelector(store => store.userReducer.user.userToken);
    const [authenticated, setAuthenticated] = useState(userToken !== null && userToken !== '');

    useEffect(() => {
        // Check if user is authenticated, otherwise redirect to login page
        if (!authenticated) {
            navigate('/login');
        }
    }, [authenticated, navigate]);

    // Render the element if user is authenticated
    return authenticated ? element : null;
}

const router = createBrowserRouter([
    {
        path: '/',
        element: <Layout/>,
        children: [
            {
                path: 'header',
                element: <Header/>
            },
            {
                path: 'footer',
                element: <Footer/>
            },
            {
                path: '',
                element: <Home/>
            },
            {
                path: 'aboutBank',
                element: <AboutBank/>
            },
            {
                path: 'services',
                element: <Services/>
            },
            {
                path: 'corporate',
                element: <CorporateClients/>
            },

            {
                path: 'personal',
                element: <PhysicalClients/>
            },
            {
                path: 'cards',
                element: <Cards/>,
            },
            {
                path: 'crypto',
                element: <CryptoOperations/>
            },
            {
                path: 'other',
                element: <OtherServices/>
            },
            {
                path: 'contact',
                element: <Contact />
            }
        ]
    },
    {
        path: '/login',
        element: <SignInSteps/>,
    },
    {
        path: '/forgot_password',
        element: <ForgotPassword/>,
    },
    {
        path: '/register',
        element: <RegistrationSteps/>,
    },
    {
        path: '/kabinet',
        element: <PrivateRoute element={<SideMenuLayout />} />,
        children: [
            {
                path: 'currencyAccounts',
                element: <Accounts/>,
            }
        ]
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router}/>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
