import React, {useEffect, useState} from 'react';
import {Field, Form, Formik} from 'formik';
import './bankTransfer.css';
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {
    GetCryptoTransactionsListThunk,
    getWalletsListThunk,
    SendCryptoTransferThunk,
    SendTransferThunk
} from "../../../store/reducers/actions";
import {setDisabled} from "../../../store/reducers/UserSlice";
import CustomModal from "../customModal/customModal";
import MyBtn from "../MyBtn/MyBtn";
import MyInput from "../MyInput/MyInput";
import Dropdown from "../Dropdown/Dropdown";

const CryptoTransferForm = () => {
    const dispatch = useAppDispatch()
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const {userToken} = useAppSelector(store => store.userReducer.user)
    const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false)
    const {isLoading} = useAppSelector(store => store.userReducer)
    const userWallets = useAppSelector(store => store.userReducer.walletsList)

    useEffect(() => {
        dispatch(getWalletsListThunk(userToken));
    }, [])

    const handleOkClick = () => {
        setIsPopupOpen(false);
        window.location.href = '/kabinet';
    };

    const currencies = [
        'BTC',
        'ETH',
        'USDT',
        'BNB',
        'SOL',
        'USDC',
    ];

    return (

        <div className='section'>
            <CustomModal isModalOpen={isPopupOpen} setIsModalOpen={setIsPopupOpen}>
                <div className="title">
                    Успешно!
                </div>
                <div className="modal-section">
                    Ваша заявка на перевод была отправлена.
                </div>
                <div className="modal-section">
                    <MyBtn medium title={"Ok"} onClick={() => {
                        handleOkClick()
                    }}/>
                </div>
            </CustomModal>

            <CustomModal isModalOpen={isErrorPopupOpen} setIsModalOpen={setIsErrorPopupOpen}>
                <div className="title">
                    Ошибка!
                </div>
                <div className="modal-section">
                    Пожалуйста, проверьте правильность введенных данных и попробуйте снова.
                </div>
                <div className="modal-section">
                    <MyBtn medium title={"Ok"} onClick={() => {
                        setIsErrorPopupOpen(false)
                    }}/>
                </div>
            </CustomModal>

            <h2 className='section-subtitle'>
                Криптоперевод
            </h2>
            <br/>
            <div className="bank-transfer-main-container">

                <Formik
                    initialValues={{
                        fromWallet: '',
                        recipient: '',
                        email: '',
                        amount: '',
                        currency: '',
                        type: 'crypto',
                    }}
                    onSubmit={(values, actions) => {
                        dispatch(setDisabled(true))

                        dispatch(SendCryptoTransferThunk(userToken, values))
                            .then((res) => {
                                if (res.data === 'Created') {
                                    console.log('created')
                                    setIsPopupOpen(true)
                                } else {
                                    console.log('error')
                                    setIsErrorPopupOpen(true)
                                }
                            })
                            .catch((e) => {
                                console.log('error')
                                setIsErrorPopupOpen(true)
                            })
                            .finally(() => {
                                localStorage.removeItem("transfer-form")
                                dispatch(setDisabled(false))
                            })

                    }}
                >
                    <Form className="transfer-form">
                        <div className="transfer-form-box">
                            <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                <span className="transfer-form-icon">* </span> Счет:
                            </label>
                            <Field name="country">
                                {({ field, form }) => (
                                    <Dropdown
                                        field={field}
                                        form={form}
                                        options={userWallets.map(account => account.number)}
                                        placeholder=""
                                    />
                                )}
                            </Field>
                        </div>

                        <h3 className="transfer-form-subtitle">
                            Детали Получателя
                        </h3>
                        <div className="transfer-form-box">
                            <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                <span className="transfer-form-icon">* </span> Номер Кошелька Получателя:
                            </label>
                            <Field
                                type="text"
                                name="recipient"
                                id="recipient"
                                placeholder=""
                                required
                                className="transfer-form-input"
                            />
                        </div>

                        <h3 className="transfer-form-subtitle">
                            Детали перевода
                        </h3>
                        <div className="transfer-form-box">
                            <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                <span className="transfer-form-icon">* </span> Сумма:
                            </label>
                            <Field
                                type="text"
                                name="amount"
                                id="amount"
                                placeholder=""
                                required
                                className="transfer-form-input"
                            />
                        </div>
                        <div className="transfer-form-box">
                            <label htmlFor="currency" className='transfer-form-label'>
                                <span className="transfer-form-icon">* </span> Валюта:
                            </label>
                            <Field name="paymentRegions">
                                {({ field, form }) => (
                                    <Dropdown
                                        field={field}
                                        form={form}
                                        options={currencies}
                                        placeholder=""
                                    />
                                )}
                            </Field>
                        </div>


                        <div className="transfer-form-box">
                            <label htmlFor="currency" className='transfer-form-label'>
                                <span className="transfer-form-icon">* </span> Причина:
                            </label>
                            <Field
                                as="textarea"
                                style={{resize: 'none'}}
                                name="reference"
                                id="reference"
                                placeholder="Введите причину перевода"
                                rows="4"
                                className="transfer-form-textarea"
                            />
                        </div>

                        <div className='transfer-form-bottom'>
                            <label  className='transfer-form-label'>
                                <span className="transfer-form-icon">* </span> Обязательные поля
                            </label>
                            <button className='secondary-button' type="submit">Подтвердить</button>
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>


    );
};

export default CryptoTransferForm;
