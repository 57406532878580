import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {CreateWalletThunk, GetCryptoTransactionsListThunk, getWalletsListThunk} from "../../../store/reducers/actions";
import CustomModal from "../customModal/customModal";
import {Field, Form, Formik} from "formik";
import MyBtn from "../MyBtn/MyBtn";
import TransactionList from "../Transactions/transactionList";
import Dropdown from "../Dropdown/Dropdown";

const CryptoWallet = () => {
    const dispatch = useAppDispatch()
    const {userToken} = useAppSelector(store => store.userReducer.user)
    const {isLoading} = useAppSelector(store => store.userReducer)
    const wallets = useAppSelector(store => store.userReducer.walletsList)
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const clientTransactions = useAppSelector(store => store.cryptoTransactionsReducer.cryptoTransactionsData)

    const currencies = [
        'BTC',
        'ETH',
        'USDT',
        'BNB',
        'SOL',
        'USDC',
    ];

    useEffect(() => {
        dispatch(getWalletsListThunk(userToken));
        dispatch(GetCryptoTransactionsListThunk(userToken));
    }, [])

    return (
        <>
            <CustomModal isModalOpen={isPopupOpen} error setIsModalOpen={setIsPopupOpen}>
                <div className="title">
                    Добавить Криптокошелек
                </div>
                <div className="modal-section">
                    Выберите валюту для нового счета.
                </div>
                <Formik
                    initialValues={{
                        currency: '',
                    }}
                    onSubmit={(values, actions) => {


                        dispatch(CreateWalletThunk(userToken, values))
                            .then((res) => {
                                if (res.status === 200) {
                                    console.log('Form submitted with data:');
                                    dispatch(getWalletsListThunk(userToken))
                                    setIsPopupOpen(false);
                                }
                            }).catch((e) => {
                            console.log('Form not submitted ');
                        })
                            .finally(() => {
                                actions.setSubmitting(false);
                            });

                    }}
                >
                    <Form>
                        <div className="modal-section add-crypto-modal">
                            <Field
                                as="select"
                                name="currency"
                                id="currency"
                                required
                            >
                                {({ field, form }) => (
                                    <Dropdown
                                        field={field}
                                        form={form}
                                        options={currencies}
                                        placeholder=""
                                    />
                                )}
                            </Field>
                        </div>

                        <div className="modal-section">
                            <MyBtn medium title={"Подтвердить"} type="submit" style={{marginRight: 8}}/>
                            <MyBtn medium title={"Отмена"} onClick={() => {
                                setIsPopupOpen(false)
                            }}/>
                        </div>

                        <div className="modal-section" style={{fontSize: 12}}>**Для открытия счета с криптовалютой, не
                            указанной в списке, свяжитесь с вашим менеджером.
                        </div>
                    </Form>
                </Formik>

            </CustomModal>

            <div className="primary-table-container">
                <table>
                    <caption>
                        <div className='table-title-content'>
                            <h3 className='section-subtitle'>
                                Криптокошельки
                            </h3>
                            <MyBtn type="submit"
                                   title={"+ Добавить Кошелек"}
                                   onClick={() => {setIsPopupOpen(true)}}
                                   className="primary-button"
                            />
                        </div>
                    </caption>
                    <thead>
                    <tr>
                        <th scope="col">
                            Номер Счета
                        </th>
                        <th scope="col">
                            Сумма
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        wallets.map((wallet) => (
                            <tr key={wallet.key}>
                                <td data-label="Получатель">
                                    {wallet.number}
                                </td>
                                <td data-label="Сумма">
                                    {wallet.balance} {wallet.currency}
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
            <TransactionList transactions={clientTransactions}/>
        </>
    );
};

export default CryptoWallet;