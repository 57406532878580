import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    transactionsData: [],
    filters: {
        accountNumber: '',
        period: null,
        from: '',
        to: '',
        purpose: '',
        recipient: '',
        amount: '',
        incoming: false,
        outgoing: false,
        currency: '',
        selectedType: '',
        templateName: '',
        paymentRegion: ''
    },
    filtersTemplates: {
        accountNumber: '',
        recipient: '',
        templateName: ''
    },
    templates: []
}

export const transactionsSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        setTransactionsData(state, action) {
            state.transactionsData = action.payload
        },
        setFilters(state, action) {
            state.filters = action.payload
        },
        setPaymentRegionFilter(state, action) {
            state.filters.paymentRegion = action.payload
        },
        setTransactionTypeFilter(state, action) {
            state.filters.selectedType = action.payload
        },
        setFiltersTemplates(state, action) {
            state.filtersTemplates = action.payload
        },
        setTemplateList(state, action) {
            state.templates = action.payload
        },
        clearTransactionSlice(state) {
            state.transactionsData = []
            state.filters = {
                accountNumber: '',
                period: null,
                from: '',
                to: '',
                purpose: '',
                recipient: '',
                amount: '',
                incoming: false,
                outgoing: false,
                currency: '',
                paymentRegion: '',
            }
            state.filtersTemplates = {
                accountNumber: '',
                recipient: '',
                templateName: ''
            }
            state.templates = []
        }
    }
})
export const {
    setTransactionsData,
    setFilters,
    setTemplateList,
    setFiltersTemplates,
    setPaymentRegionFilter,
    setTransactionTypeFilter
} = transactionsSlice.actions;

export default transactionsSlice.reducer
