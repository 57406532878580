import {Api} from "../../api/Api";
import {setAccountsList, setDisabled, setIsLoading, setTokenStatus, setWalletsList, userSlice} from "./UserSlice";
import {setTransactionsData, transactionsSlice} from "./TransactionsSlice";
import {cryptoTransactionsSlice, setCryptoTransactionsData} from "./CryptoTransactionsSlice";

export const getLoginList = (data) => async (dispatch) => {
        return await Api.login(data);
};

export const finalLogin = (data) => async (dispatch) => {
        localStorage.clear()
        /*     dispatch(setDisabled(true))*/
        const response = await Api.finalLogin(data)
        console.log(response.data)
        await dispatch(userSlice.actions.setUserData(response.data))
        return response
};

export const postRegistration = (data) => async (dispatch) => {
        return await Api.register(data);
};

export const resetPassword = (data) => async () => {
        return await Api.resetPassword(data);
}

export const setTokenState = (isValid) => async (dispatch) => {
        dispatch(setTokenStatus(isValid))
}


export const getAccountsListThunk = (token) => async (dispatch) => {
        try {
                dispatch(setIsLoading(true))
                const response = await Api.getAccountsList(token)

                dispatch(setAccountsList(response.data))
        } catch (e) {
                if (e.response.data === "Invalid authorization data") {
                        dispatch(setTokenState(false))
                }
        } finally {
                dispatch(setIsLoading(false))
        }
}

export const LogoutThunk = () => async (dispatch) => {
        try {
                dispatch(setDisabled(true))
                dispatch(userSlice.actions.signOut())
                dispatch(transactionsSlice.actions.clearTransactionSlice())
                dispatch(cryptoTransactionsSlice.actions.clearCryptoTransactionSlice())
                dispatch(setDisabled(false))
        } catch (e) {
                console.error(e)
                dispatch(setDisabled(false))
        }
}


export const sendPersonalKycThunk = (token: string, data) => async (dispatch) => {
        dispatch(setDisabled(true))
        const res = await Api.sendPersonalKyc(token, data)
        return res
}

export const getKycListThunk = (token: string) => async (dispatch) => {
        try {
                const response = await Api.getKycList(token)
                dispatch(userSlice.actions.setKycList(response.data))
                return response
        } catch (e) {
                if (e.response.data === "Invalid authorization data") {
                        dispatch(setTokenState(false))
                }
        }
}

export const deleteKycThunk = (token: string, id: number) => async (dispatch) => {
        try {
                await Api.deleteKyc(token, id);
                dispatch(getKycListThunk(token))
        } catch (e) {
                if (e.response.data === "Invalid authorization data") {
                        dispatch(setTokenState(false))
                }
        }
}

export const editKycThunk = (token: string, data) => async (dispatch) => {
        return await Api.updateKyc(token, data)
}

export const CreateAccountsThunk = (token, accounts) => async (dispatch) => {
        try {
                dispatch(setDisabled(true))
                return await Api.createAccounts(token, accounts)
        } catch (e) {
                if (e.response.data === "Invalid authorization data") {
                        dispatch(setTokenState(false))
                }
        } finally {
                dispatch(setDisabled(false))
        }
}

export const GetTransactionsListThunk = (token: string) => async (dispatch) => {
        try {
                const response = await Api.getTransactionsList(token)
                dispatch(setTransactionsData(response.data))
        } catch (e) {
                if (e.response.data === "Invalid authorization data") {
                        dispatch(setTokenState(false))
                }
        }
}

export const SendTransferThunk = (token, dataForTransaction) => async (dispatch) => {
        try {
                const res = await Api.sendTransfer(token, dataForTransaction)
                dispatch(GetTransactionsListThunk(token))
                return res
        } catch (e) {
                if (e.response.data === "Invalid authorization data") {
                        dispatch(setTokenState(false))
                }
        }
}
export const getWalletsListThunk = (token) => async (dispatch) => {
        try {
                dispatch(setIsLoading(true))
                const response = await Api.getWalletsList(token)

                dispatch(setWalletsList(response.data))
        } catch (e) {
                if (e.response.data === "Invalid authorization data") {
                        dispatch(setTokenState(false))
                }
        } finally {
                dispatch(setIsLoading(false))
        }
}

export const CreateWalletThunk = (token, wallet) => async (dispatch) => {
        try {
                dispatch(setDisabled(true))
                return await Api.createWallet(token, wallet)
        } catch (e) {
                if (e.response.data === "Invalid authorization data") {
                        dispatch(setTokenState(false))
                }
        } finally {
                dispatch(setDisabled(false))
        }
}


export const GetCryptoTransactionsListThunk = (token: string) => async (dispatch) => {
        try {
                const response = await Api.getCryptoTransactionsList(token)
                dispatch(setCryptoTransactionsData(response.data))
        } catch (e) {
                if (e.response.data === "Invalid authorization data") {
                        dispatch(setTokenState(false))
                }
        }
}

export const SendCryptoTransferThunk = (token, dataForTransaction) => async (dispatch) => {
        try {
                const res = await Api.sendCryptoTransfer(token, dataForTransaction)
                dispatch(GetCryptoTransactionsListThunk(token))
                return res
        } catch (e) {
                if (e.response.data === "Invalid authorization data") {
                        dispatch(setTokenState(false))
                }
        }
}

export const createNewPasswordThunk = (token, data) => async (dispatch) => {
        try {
                return await Api.createNewPassword(token, data);
        } catch (error) {
                if (error.response.data === "Invalid authorization data") {
                        dispatch(setTokenState(false));
                }
        }
};

export const sendContactFormThunk = (data) => async (dispatch) => {
        try {
                const response = await Api.sendContactForm(null, data);
                console.log("Contact form sent successfully", response.data);
                return response.data;
        } catch (error) {
                console.error("Error sending contact form", error);
                throw error;
        }
};
