import React, {useState} from 'react';
import { Formik, Form, Field } from 'formik';
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {setDisabled} from "../../../store/reducers/UserSlice";
import {sendPersonalKycThunk, setTokenState} from "../../../store/reducers/actions";
import CustomModal from "../customModal/customModal";
import MyBtn from "../MyBtn/MyBtn";
import Dropdown from '../Dropdown/Dropdown'
const KycForm = () => {
    const dispatch = useAppDispatch()
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const {userToken} = useAppSelector(store => store.userReducer.user)
    const accountType = useAppSelector(store => store.userReducer.user.userData.accountType)
    const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false)
    const idCardsTypes = ["Паспорт", "Национальное ID", "Водительские Права"];
    const kycStatus = useAppSelector(state => state.userReducer.user.flags.kycStatus)
    const [formSubmitted, setFormSubmitted] = useState(false); // Initialize formSubmitted state


    const countries = [
        "Afghanistan",
        "Aland Islands",
        "Albania",
        "Algeria",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bosnia",
        "Bosnia and Herzegovina",
        "Botswana",
        "Brazil",
        "British Indian Ocean Territory",
        "British Virgin Islands",
        "Brunei",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cape Verde",
        "Cayman islands",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos Islands",
        "Colombia",
        "Comoros",
        "Congo",
        "Cook Islands",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Curacao",
        "Cyprus",
        "Czech",
        "Democratic People's Republic of Korea",
        "Democratic Republic of the Congo",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "East Timor",
        "Ecuador",
        "Egypt",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Ethiopia",
        "Falkland Islands",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "French guiana",
        "French polynesia",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Great Britain",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Israel",
        "Italy",
        "Ivory Coast",
        "Jamaica",
        "Japan",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Kuwait",
        "Kyrgyzstan",
        "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macao",
        "Macedonia",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Minor Outlying Islands (USA)",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "Northern Mariana Islands",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestinian territories",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Pitcairn",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Romania",
        "Russian Federation",
        "Rwanda",
        "Saint Bartholomew Island",
        "Saint Helena",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Martin's Island",
        "Saint Pierre and Miquelon",
        "Saint Vincent and the Grenadines",
        "Salvador",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Sint Maarten",
        "Slovakia",
        "Slovenia",
        "Solomon islands",
        "Somalia",
        "South Africa",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Swaziland",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "The Republic of Korea",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands",
        "Tuvalu",
        "UAE",
        "Uganda",
        "Ukraine",
        "United States",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Vatican",
        "Venezuela",
        "Vietnam",
        "Virgin Islands (US)",
        "Wallis and Futuna Islands",
        "Yemen",
        "Zambia",
        "Zimbabwe",
    ]


    return (

        <div>

            <CustomModal isModalOpen={isPopupOpen} setIsModalOpen={setIsPopupOpen}>
                <div className="title">
                    Успешно!
                </div>
                <div className="modal-section">
                    Ваша заявка была отправлена на рассмотрение.
                </div>
                <div className="modal-section">
                    <MyBtn medium title={"Ok"} onClick={() => {
                        setIsPopupOpen(false)
                    }}/>
                </div>
            </CustomModal>

            <CustomModal isModalOpen={isErrorPopupOpen} setIsModalOpen={setIsErrorPopupOpen}>
                <div className="title">
                    Ошибка!
                </div>
                <div className="modal-section">
                    Пожалуйста, проверьте правильность введенных данных и попробуйте снова.
                </div>
                <div className="modal-section">
                    <MyBtn medium title={"Ok"} onClick={() => {
                        setIsErrorPopupOpen(false)
                    }}/>
                </div>
            </CustomModal>

            <h2 className='section-subtitle'>
                Верификация Клиента
            </h2>
            <br/>

            {(kycStatus === 'New' || formSubmitted) ?
                <div className="bank-transfer-main-container">
                    <h3 style={{color: '#03045E'}}>
                        Ваша заявка на верификацию была отправлена на рассмотрение.
                    </h3>
                </div>
                :
                <div className="bank-transfer-main-container">
                    <Formik
                        initialValues={{
                            firstName: '',
                            lastName: '',
                            companyName: '',
                            country: '',
                            city: '',
                            state: '',
                            postalCode: '',
                            identityCardType: '',
                            identityCardId: '',
                            address: '',
                            phoneNumber: '',
                            taxNumber: '',
                            sourcesOfWealth: [],
                            isOtherSourcesOfWealth: false,
                            otherSourcesOfWealth: '',
                            pep: false,
                            currencies: [],
                        }}
                        onSubmit={(values, actions) => {
                            dispatch(setDisabled(true))

                            dispatch(sendPersonalKycThunk(userToken, values))
                                .then((res) => {
                                    if (res.data === "Created") {
                                        setIsPopupOpen(true)
                                        setFormSubmitted(true);
                                    } else {
                                        setIsErrorPopupOpen(true)
                                    }
                                })
                                .catch((error) => {
                                    console.log(error)
                                    setIsErrorPopupOpen(true)
                                    if (error.response.data === "Invalid authorization data") {
                                        dispatch(setTokenState(false))
                                    }
                                    /*
                                                                    if (error.response.data === "Too many phones. Max count is 5") {
                                                                        setErrorMessage(error.response.data)
                                                                        setIsErrorModalOpen(true)
                                                                        return
                                                                    }

                                                                    if (error.response.data === "Phone already exist") {
                                                                        setErrorMessage("This phone number already exists. Please try another phone number.")
                                                                        setIsErrorModalOpen(true)
                                                                        return
                                                                    }

                                                                    if (error.code === "ERR_NETWORK" && !error.response) {
                                                                        setErrorMessage("Maximum files size is 90 MB")
                                                                        setIsErrorModalOpen(true)
                                                                    } else {
                                                                        setErrorMessage("Something went wrong. Please try again later.")
                                                                        setIsErrorModalOpen(true)
                                                                    }
                                                                    */
                                })
                                .finally(() => {
                                    dispatch(setDisabled(false))
                                })
                        }}
                    >
                        <Form className="transfer-form">
                            {accountType === 'business' &&
                                <div className="transfer-form-box">
                                    <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                        <span className="transfer-form-icon">* </span> Имя Компании:
                                    </label>
                                    <Field type="text"
                                           name="companyName"
                                           id="companyName"
                                           placeholder=""
                                           required
                                           className="transfer-form-input"
                                    />
                                </div>
                            }

                            {accountType === 'personal' &&
                                <div className='transfer-form-group'>
                                    <div className="transfer-form-box">
                                        <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                            <span className="transfer-form-icon">* </span>Имя:
                                        </label>
                                        <Field
                                            type="text"
                                            name="firstName"
                                            id="firstName"
                                            placeholder=""
                                            required
                                            className="transfer-form-input"
                                        />
                                    </div>
                                    <div className="transfer-form-box">
                                        <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                            <span className="transfer-form-icon">* </span>Фамилия:
                                        </label>
                                        <Field
                                            type="text"
                                            name="lastName"
                                            id="lastName"
                                            placeholder=""
                                            required
                                            className="transfer-form-input"
                                        />
                                    </div>
                                </div>
                            }

                            <div className="transfer-form-box">
                                <label htmlFor="country" className="transfer-form-label">
                                    <span className="transfer-form-icon">* </span>Страна:
                                </label>
                                <Field name="country">
                                    {({ field, form }) => (
                                        <Dropdown
                                            field={field}
                                            form={form}
                                            options={countries}
                                            placeholder=""
                                        />
                                    )}
                                </Field>
                            </div>

                            <div className='transfer-form-group'>

                                <div className="transfer-form-box">
                                    <label htmlFor="phoneNumber" className='transfer-form-label'>
                                        <span className="transfer-form-icon">* </span>Номер телефона:
                                    </label>
                                    <Field
                                        type="text"
                                        name="phoneNumber"
                                        id="phoneNumber"
                                        placeholder=""
                                        required
                                        className="transfer-form-input"
                                    />
                                </div>
                                <div className="transfer-form-box">
                                    <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                        <span className="transfer-form-icon">* </span>Город:
                                    </label>
                                    <Field
                                        type="text"
                                        name="city"
                                        id="city"
                                        placeholder=""
                                        className="transfer-form-input"
                                    />
                                </div>
                            </div>

                            <div className="transfer-form-box">
                                <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                    <span className="transfer-form-icon">* </span>Адрес:
                                </label>
                                <Field
                                    type="text"
                                    name="address"
                                    id="address"
                                    placeholder=""
                                    className="transfer-form-input"
                                />
                            </div>

                            <div className='transfer-form-group'>
                                <div className="transfer-form-box">
                                    <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                        <span className="transfer-form-icon">* </span>Тип удостоверения личности:
                                    </label>
                                    <Field as="select"
                                           name="identityCardType"
                                           id="identityCardType"
                                           className="transfer-form-input"
                                    >
                                        <option style={{display: 'none'}}></option>
                                        {idCardsTypes.map(type => (
                                            <option key={type.key} value={type}>{type}</option>
                                        ))}
                                    </Field>
                                </div>
                                <div className="transfer-form-box">
                                    <label htmlFor="transfer-form-label" className='transfer-form-label'>
                                        <span className="transfer-form-icon">* </span>Номер:
                                    </label>
                                    <Field
                                        type="text"
                                        name="identityCardId"
                                        id="identityCardId"
                                        placeholder=""
                                        className="transfer-form-input"
                                    />
                                </div>
                            </div>
                            <div className='transfer-form-bottom'>
                                <label className='transfer-form-label'>
                                    <span className="transfer-form-icon">* </span> Обязательные поля
                                </label>
                                <button className='secondary-button' type="submit">Подтвердить</button>
                            </div>
                        </Form>
                    </Formik>
                </div>

            }
        </div>
    );
};

export default KycForm;









