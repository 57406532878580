import React, {useEffect, useState} from "react";
import "./currencyAccounts.css";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {CreateAccountsThunk, getAccountsListThunk, GetTransactionsListThunk} from "../../../store/reducers/actions";
import MyBtn from "../MyBtn/MyBtn";
import CustomModal from "../customModal/customModal";
import {Field, Form, Formik} from "formik";
import TransactionList from "../Transactions/transactionList";
import Dropdown from "../Dropdown/Dropdown";

const Accounts = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const dispatch = useAppDispatch()
    const {userToken} = useAppSelector(store => store.userReducer.user)
    const {isLoading} = useAppSelector(store => store.userReducer)
    const accounts = useAppSelector(store => store.userReducer.accountsList)
    const clientTransactions = useAppSelector(store => store.transactionsReducer.transactionsData)

    useEffect(() => {
        dispatch(getAccountsListThunk(userToken));
        dispatch(GetTransactionsListThunk(userToken));
    }, [dispatch, userToken]);

    const currencies = [
        'USD',
        'EUR',
        'GBP',
        'CHF',
        'RUB',
        'TRY',
        'AED',
        'CNY',
        'AUD',
        'CZK',
        'PLN',
        'CAD',
        'USDT'
    ];


    return (
        <>
            <CustomModal isModalOpen={isPopupOpen} error setIsModalOpen={setIsPopupOpen} className='add-account-container'>
                <div className="title">
                    Добавить Счет
                </div>
                <div className="modal-section">
                    Выберите валюту для нового счета.
                </div>
                <Formik
                    initialValues={{
                        currency: '',
                    }}
                    onSubmit={(values, actions) => {
                        let transformedValues = {
                            currencies: [values.currency.trim()]
                        };

                        dispatch(CreateAccountsThunk(userToken, transformedValues))
                            .then((res) => {
                                if (res.status === 200) {
                                    console.log('Form submitted with data:');
                                    dispatch(getAccountsListThunk(userToken))
                                    setIsPopupOpen(false);
                                }
                            }).catch((e) => {
                            console.log('Form not submitted ');
                        })
                            .finally(() => {
                                actions.setSubmitting(false);
                            });

                    }}
                >
                    <Form>
                        <div className="modal-section add-account-modal" >

                            <Field as="select" name="currency" id="currency" required

                            >
                                {({ field, form }) => (
                                    <Dropdown
                                        field={field}
                                        form={form}
                                        options={currencies}
                                        placeholder=""
                                    />
                                )}
                            </Field>
                        </div>

                        <div className=" add-account-buttons">
                            <MyBtn medium title={"Подтвердить"} type="submit"
                                   style={{width: '40%'}}
                            />
                            <MyBtn medium title={"Отмена"} onClick={() => {
                                setIsPopupOpen(false)
                            }}
                                   style={{width: '40%'}}
                            />
                        </div>
                    </Form>
                </Formik>
            </CustomModal>

            <div className="primary-table-container">
                <table>
                    <caption>
                        <div className='table-title-content '>
                            <h3 className='section-subtitle'>
                            Валютные Счета
                            </h3>

                            <MyBtn type="submit" title={"+ Добавить Счет"} onClick={() => {
                                setIsPopupOpen(true)
                            }} className="primary-button"
                            />
                        </div>
                    </caption>


                    <thead>
                    <tr>
                        <th scope="col">
                            Номер Счета
                        </th>
                        <th scope="col">
                            Сумма
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        accounts.map((account) => (
                            <tr key={account.key}>
                                <td data-label="Получатель">
                                    {account.number}
                                </td>
                                <td data-label="Сумма">
                                    {account.balance} {account.currency}
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>

            <br/>
            <br/>

            <TransactionList transactions={clientTransactions}/>
        </>
    );
};

export default Accounts;
