import React, { useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import './Layout.css';

function Layout() {
    const headerRef = useRef(null);

    useEffect(() => {
        if (headerRef.current) {
            const headerHeight = headerRef.current.offsetHeight;
            document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);
        }
    }, []);

    return (
        <div className="layout-container">
            <div className="desktop-header" ref={headerRef}>
                <Header />
            </div>
            <div className="content-container">
                <Outlet />
            </div>
            <div className="footer-container">
                <Footer />
            </div>
        </div>
    );
}

export default Layout;


