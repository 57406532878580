import React, {useState} from 'react';
import './bankTransfer.css';
import BankTransferForm from "./banktransferForm";
import IntraTransferForm from "./intraTransferForm";

const TransferForm = () => {
    const [activeTab, setActiveTab] = useState('bankTransfer'); // Initially set to 'bankTransfer'

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className='bank-transfer-container'>
            <div className='bank-transfer-button'>
                <button
                    onClick={() => handleTabChange('bankTransfer')}
                    className={`tab-button ${activeTab === 'bankTransfer' ? 'active' : ''}`}
                >
                    Банковский Перевод
                </button>
                <button
                    onClick={() => handleTabChange('intraTransfer')}
                    className={`tab-button ${activeTab === 'intraTransfer' ? 'active' : ''}`}
                >
                    Перевод на получателя СберКассы
                </button>
            </div>
            <div>
                {activeTab === 'bankTransfer' && <BankTransferForm />}
                {activeTab === 'intraTransfer' && <IntraTransferForm />}
            </div>
        </div>
    );
};

export default TransferForm;
