import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { sendContactFormThunk } from "../../store/reducers/actions";
import './ContactForm.css';
import MyBtn from "../ui/MyBtn/MyBtn";
import CustomModal from "../ui/customModal/customModal";

const ContactForm = () => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: ""
    });

    const [isPopupOpen, setIsPopupOpen] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await dispatch(sendContactFormThunk(formData));
            setIsPopupOpen(true);
        } catch (error) {
            alert("Произошла ошибка. Пожалуйста, попробуйте еще раз.");
        }
    };

    return (
        <div>
            <CustomModal isModalOpen={isPopupOpen} setIsModalOpen={setIsPopupOpen}>
                <div className="title">
                    Успешно!</div>
                <div className="modal-section">
                    Ваша заявка была отправлена.
                </div>
                <div className="modal-section">
                    <MyBtn medium title={"Ok"} onClick={() => {setIsPopupOpen(false)}}/>
                </div>
            </CustomModal>

            <form onSubmit={handleSubmit} className="contact-form">
                <div className="contact-input-block">
                    <label className="label">ФИО контактного лица</label>
                    <input
                        className='my_input'
                        placeholder='Виктория Давлатова'
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>

                <div className="contact-input-block">
                <label className="label">E-mail</label>
                    <input
                        className='my_input'
                        placeholder='bub@mail.ru'
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>

                <br/>

                <div className='contact-input-block'>
                    <label className="label">телефон</label>
                    <textarea
                        placeholder='Контактный номер телефона'
                        rows="10"
                        cols="50"
                        className='my_input'
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        style={{ height: '100px', marginBlock: '0' }}
                    />
                </div>
                <br/>
                <MyBtn
                    title="Отправить"
                    large
                    style={{ width: '420px' }}
                    type="submit"
                />
            </form>
        </div>
    );
};

export default ContactForm;
