import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    cryptoTransactionsData: [],
    filters: {
        accountNumber: '',
        period: null,
        from: '',
        to: '',
        purpose: '',
        recipient: '',
        amount: '',
        incoming: false,
        outgoing: false,
        currency: '',
        selectedType: '',
        templateName: '',
        paymentRegion: ''
    },
    filtersTemplates: {
        accountNumber: '',
        recipient: '',
        templateName: ''
    },
    templates: []
}

export const cryptoTransactionsSlice = createSlice({
    name: 'cryptoTransactions',
    initialState,
    reducers: {
        setCryptoTransactionsData(state, action) {
            state.cryptoTransactionsData = action.payload
        },
        setCryptoFilters(state, action) {
            state.filters = action.payload
        },
        setCryptoPaymentRegionFilter(state, action) {
            state.filters.paymentRegion = action.payload
        },
        setCryptoTransactionTypeFilter(state, action) {
            state.filters.selectedType = action.payload
        },
        setCryptoFiltersTemplates(state, action) {
            state.filtersTemplates = action.payload
        },
        setCryptoTemplateList(state, action) {
            state.templates = action.payload
        },
        clearCryptoTransactionSlice(state) {
            state.cryptoTransactionsData = []
            state.filters = {
                accountNumber: '',
                period: null,
                from: '',
                to: '',
                purpose: '',
                recipient: '',
                amount: '',
                incoming: false,
                outgoing: false,
                currency: '',
                paymentRegion: '',
            }
            state.filtersTemplates = {
                accountNumber: '',
                recipient: '',
                templateName: ''
            }
            state.templates = []
        }
    }
})
export const {
    setCryptoTransactionsData,
    setCryptoFilters,
    setCryptoTemplateList,
    setCryptoFiltersTemplates,
    setCryptoPaymentRegionFilter,
    setCryptoTransactionTypeFilter
} = cryptoTransactionsSlice.actions;

export default cryptoTransactionsSlice.reducer
