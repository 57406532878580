import React from 'react';
import {useNavigate} from 'react-router-dom';
import './CryptoOperations.css'
import Crypto from '../Assets/cryptoIcons/crypto.svg'
import HandShake from '../Assets/cryptoIcons/hands.png'
import MyBtn from '../ui/MyBtn/MyBtn';
import Notes from "../Assets/clients/notes.svg";
import maincard from "../Assets/cardIcons/mainCard.png";


function CryptoOperations() {
    const navigate = useNavigate();

    return (
        <>
            <section className='section'>
                <div className='crypto-container container grid'>

                    <div className='crypto-main-content'>
                        <div className='card-details'>

                            <h2 className='section-title'>
                                Операции с Криптовалютой
                            </h2>

                            <p className="cards-description">
                                Мы поддерживаем ĸонцепцию диджитализации и децентрализации валюты в современном
                                мире. Также Международная СберКасса предоставляет возможность криптообменных
                                операций. У нас нет ограничений на объемы обмена криптовалют, поэтому вы можете
                                свободно конвертировать любую сумму. Международная СберКасса взимает до 2,5
                                процента
                                за конвертацию в зависимости от объема.
                            </p>

                            <MyBtn title="Открыть Счет" large onClick={() => navigate("/register")}/>
                        </div>

                        <div className='cards-main-image'>
                            <img src={Crypto}
                                 alt='cards-section-img'
                                 className='cards-main-img'
                            />
                        </div>
                    </div>

                    <div className='crypto-cards-container'>

                        <h2 className='section-title'>
                            Международная СберКасса предоставляет
                            платежный шлюз для криптоплатежей
                        </h2>
                        <p className='crypto-description'>
                            Это сервис, который позволяет продавцам обрабатывать платежи
                            криптовалютах. Он действует как посредник и выполняет 6 основных функций:
                        </p>

                        <div className='crypto-grid' >
                            <div className='crypto-column' >
                                <div className='crypto-row'>
                                    <article className='crypto-row-cell'>
                                        Регистрирует данные
                                        плательщика
                                    </article>
                                    <article className='crypto-row-cell'>
                                        Отправляет данные транзакции в блокчейн
                                        и регистрирует подтверждения от узлов
                                    </article>
                                </div>
                                <div className='crypto-row'>
                                    <article className='crypto-row-cell'>
                                        Конвертирует
                                        полученные монеты в
                                        фиатные деньги (по
                                        запросу мерчанта)
                                    </article>
                                    <article className='crypto-row-cell'>
                                        Принимает платежи от покупателя
                                    </article>
                                    <article className='crypto-row-cell'>
                                        Переводит платежи мерчанту
                                    </article>
                                </div>
                            </div>
                            <div className='crypto-column'
                                 style={{
                                     backgroundImage: `url(${HandShake})`,
                                     backgroundSize: "cover",
                                     backgroundPosition: "center",
                                     backgroundRepeat: 'no-repeat'
                                 }}
                            >
                                <p className='crypto-column-text'>
                                    Предоставляет подтверждение обработки платежа для обеих сторон
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    );

}


export default CryptoOperations;