import React from 'react'
import {useNavigate} from 'react-router-dom';
import MyBtn from '../ui/MyBtn/MyBtn'
import maincard from '../Assets/cardIcons/mainCard.png'
import creditCard from '../Assets/cardIcons/creditCard.png'
import physicalCard from '../Assets/cardIcons/physicalCard.png'
import tick from '../Assets/cardIcons/tick.png'

import './Cards.css'


function Cards() {
    const navigate = useNavigate();


    return (
        <section className='section'>
            <div className='cards-main-container container grid '>
                <div className='cards-content-wrapper'>
                    <div className='card-details'>
                        <h2 className='section-title'>
                            Дебетовые Карты
                        </h2>
                        <p className="cards-description">
                            Дебетовые карты (пластиковые, металлические, виртуальные, неименные) -
                            возможность осуществлять безналичные расчеты, снятие наличных, перевод с
                            карты на карту, а также другие операции по запросу через Ваш Онлайн-
                            Банкинг.
                        </p>

                        <MyBtn title="Заказать Карту" large
                               onClick={() => navigate("/register")}
                        />
                    </div>

                    <div className='cards-main-image'>
                        <img src={maincard}
                             alt='cards-section-img'
                             className='cards-main-img'
                        />
                    </div>
                </div>

                <div className='virtual-card-container'>

                    <div className='virtual-card '>
                        <article className='virtual-details'>
                            <div className='virtual-content'>
                                <h1 className='section-subtitle '>
                                    Виртуальная карта
                                </h1>
                                <div className='virtual-card-details'>
                                <span className='virtual-card-description'>Снятие наличных с банковской карты в банкоматах
                                    <img src={tick} alt='red tick' className='virtual-card-icon'/>
                                </span>
                                    <span
                                        className='virtual-card-description'>Перевод денежных средств с карты на карту  <img
                                        src={tick} alt='red tick' className='virtual-card-icon'/></span>
                                    <span
                                        className='virtual-card-description'>Поддержка банковской картой технологии NFC  <img
                                        src={tick} alt='red tick' className='virtual-card-icon'/></span>
                                    <span className='virtual-card-description'>Осуществление онлайн платежей  <img
                                        src={tick} alt='red tick' className='virtual-card-icon'/></span>
                                </div>

                                <div className='virtual-card-row'>
                                    <div>
                                        <span className='primary-card-cell'>Платежная система</span>
                                        <span className='secondary-card-cell'>MasterCard</span>
                                    </div>
                                    <div>
                                        <span className='primary-card-cell'>Валюта</span>
                                        <span className='secondary-card-cell'>USD</span>
                                    </div>
                                    <div>
                                        <span className='primary-card-cell'>Срок действия</span>
                                        <span className='secondary-card-cell'>5 лет</span>
                                    </div>
                                    <div>
                                        <span className='primary-card-cell'>Обслуживание</span>
                                        <span className='secondary-card-cell'>Бесплатно</span>
                                    </div>
                                </div>
                            </div>
                        </article>
                        <img src={creditCard}
                             alt=''
                             className='virtual-card-img'
                        />
                    </div>

                    <br/>

                    <article className='virtual-card '>
                        <div className='virtual-details'>
                            <div className='virtual-content'>
                                <h1 className='section-subtitle '>
                                    Физическая карта
                                </h1>
                                <div className='virtual-card-details'>
                                <span className='virtual-card-description'>
                                    Снятие наличных с банковской карты в банкоматах
                                    <img src={tick} alt='red tick' className='virtual-card-icon'/>
                                </span>
                                    <span className='virtual-card-description'>
                                    Перевод денежных средств с карты на карту
                                    <img src={tick} alt='red tick' className='virtual-card-icon'/>
                                </span>
                                    <span className='virtual-card-description'>
                                    Поддержка банковской картой технологии NFC
                                    <img src={tick} alt='red tick' className='virtual-card-icon'/>
                                </span>
                                    <span className='virtual-card-description'>
                                    Осуществление онлайн платежей
                                    <img src={tick} alt='red tick' className='virtual-card-icon'/>
                                </span>
                                </div>

                                <div className='virtual-card-row'>
                                    <div>
                                        <span className='primary-card-cell'>Платежная система</span>
                                        <span className='secondary-card-cell'>MasterCard</span>
                                    </div>
                                    <div>
                                        <span className='primary-card-cell'>Валюта</span>
                                        <span className='secondary-card-cell'>USD</span>
                                    </div>
                                    <div>
                                        <span className='primary-card-cell'>Срок действия</span>
                                        <span className='secondary-card-cell'>5 лет</span>
                                    </div>
                                    <div>
                                        <span className='primary-card-cell'>Обслуживание</span>
                                        <span className='secondary-card-cell'>Бесплатно</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img src={physicalCard}
                             alt=''
                             className='virtual-card-img'
                        />
                    </article>
                </div>

            </div>

        </section>
    )
}

export default Cards