import React, {useState} from 'react'
import './aboutBank.css'
import MyBtn from '../ui/MyBtn/MyBtn'
import {useNavigate} from 'react-router-dom';
import Megaphone from '../Assets/bankIcons/building.svg'
import lightbulb from '../Assets/bankIcons/lightbulb.png'
import vector from '../Assets/bankIcons/Vector.png'
import vector1 from '../Assets/bankIcons/Vector1.png'
import card1 from '..//Assets/bankIcons/card1.png'
import card2 from '../Assets/bankIcons/card2.png'
import card3 from '../Assets/bankIcons/card3.png'
import rocket from '../Assets/profileImage.png'


function AboutBank() {
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);

    return (
        <div className='about-section section'>
                    <div className='about-container container grid'>

                        <div className="primary-home-content grid">
                            <div>
                                <h2 className="bankTextHeader">
                                    О БАНКЕ
                                </h2>
                                <p className="bank-text">
                                    Международная СберКасса - это современный Банк, обслуживающий
                                    индивидуальных и корпоративных клиентов различных форм собственности и
                                    направлений деятельности. В рамках современного дистанционного
                                    банковского обслуживания, клиенты Банка эффективно используют систему
                                    «Онлайн-Банкинга».
                                </p>
                                <MyBtn title="Открыть счет" large
                                       onClick={() => navigate("/register")}
                                />
                            </div>

                            <div className='home-img-container'>
                                <img src={Megaphone}
                                     alt='vectore'
                                     className='home-img'
                                />
                            </div>
                        </div>

                        <div className='about-bank-content'>
                        <div>
                                <p className='centralTextHeader'>
                                    Банк, предлагающий
                                    инновационные решения
                                </p>
                                <div className='centralPara'>
                                    <p>
                                        Развитие бизнеса Банка строится на предоставлении клиентам широкого <br/>
                                        спектра высокотехнологических банковских продуктов и услуг на уровне <br/>
                                        международных стандартов.
                                    </p>
                                    <p>
                                        Международная СберКасса постоянно изучает и прогнозирует потребности <br/>
                                        клиентов, осуществляет поиск новых решений, применяет передовые <br/>
                                        технические средства хранения, защиты, обработки и передачи информации, <br/>
                                        минимизирует бюрократические процедуры, что позволяет максимально <br/>
                                        сократить время прохождения проверок, платежей и всех видов расчетов.
                                    </p>
                                    <p>
                                        В основе взаимоотношений Банка с клиентами лежит принцип партнерства.<br/>
                                        Банк ориентирован на долгосрочное и взаимовыгодное сотрудничество.
                                    </p>
                                </div>
                            </div>

                            <div className='about-vectors'>
                                <img
                                    src={lightbulb}
                                    alt="about-vector"
                                    className='about-vector-one'

                                />
                                <img src={vector1}
                                     alt='about vector line'
                                     className='about-vector-two'
                                />
                                <img src={vector}
                                     alt='about-vector'
                                     className='about-vector-three'
                                />
                            </div>
                        </div>
                        
                        <div className="about-card-container">
                            <article
                                className="cards"
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                            >
                                <img src={card2} alt="Card 2"/>
                                <div className="cardText" style={{display: isHovered ? 'flex' : 'none'}}>
                                    Контроль Центробанка Коморских
                                    О-вов: Банковский надзор за деятельностью финансовой организации "Международная СберКасса", рег. No: B2023080, осуществляет Mwali International Services Authority, BP 724, г. Фомбони,
                                    автономный регион Союза Коморских Островов Мвали, Восточная Африка.
                                </div>
                                <p className="cardText" style={{display: isHovered ? 'none' : 'flex'}}>
                                    Контроль Центробанка Коморских
                                    О-вов: Банковский надзор за деятельн...
                                </p>
                            </article>

                            <article className="cards">
                                <img src={card3} alt="Card 3"/>
                                <p className="cardText">
                                    Уставной капитал Банка 1,437,714,201.00 Долларов США
                                </p>
                            </article>

                            <article className="cards" onMouseEnter={() => setIsHovered2(true)}
                                 onMouseLeave={() => setIsHovered2(false)}>
                                <img src={card1} alt="Card 1"/>
                                <p className="cardText" style={{display: isHovered2 ? 'flex' : 'none'}}>
                                    Контактные данные учреждения по надзору финансовых институтов на территории
                                    Коморских О-вов: www.mwaliregistrar.com misa@mwaliregistrar.com Звонки
                                    принимаются круглосуточно.
                                </p>
                                <p className="cardText" style={{display: isHovered2 ? 'none' : 'flex'}}>
                                    Контактные данные учреждения по надзору финансовых институтов на территории
                                    Коморских О-вов:
                                </p>
                            </article>
                        </div>
                        
                        <div className='rocketContainer'>

                            <img src={rocket} alt="Rocket" className='rocketImage'/>
                            <div className='underRocketText'>
                                Готовы взлететь с нами в <br/>
                                бесконечные финансовые просторы?
                            </div>

                            <div className="btn-block">
                                <MyBtn
                                    title="Начните Сейчас" large
                                    onClick={() => navigate("/register")}
                                />
                            </div>
                        </div>

                    </div>
                </div>
    )
}

export default AboutBank
