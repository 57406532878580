import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './templateSheet.css';

const TemplateSheet = () => {
  const [entries, setEntries] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('our-api-url');
      setEntries(response.data);
    } catch (error) {
      console.error('Error fetching data Please try again:', error);
    }
  };

  return (
      <div className="table-container">
            <h3>Раздел находится в разработке. Благодарим за понимание.</h3>
      </div>
  );
};

export default TemplateSheet;
