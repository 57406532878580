import React from 'react';
import './customModal.css';
const CustomModal = ({ setIsModalOpen, isModalOpen, children, type }) => {
  return (
      <>
        {isModalOpen && (
            <div className="modal-wrapper" >
              <div
                  className={`content_wrapper ${type === 'confirm' ? 'confirmModal' : ''} ${type === 'error' ? 'error' : ''} ${type === 'contact' ? 'contact' : ''}`}
              >
                <div className={type === 'contact' ? 'short_content' : 'content'}>
                  {children}
                </div>
              </div>
            </div>

        )}
      </>
  );
};

export default CustomModal;
