import React, { useEffect, useState, useRef, useCallback } from 'react';
import './SideMenu.css';

import btc from '../Assets/sideMenuIcons/btc.png';
import settings from '../Assets/sideMenuIcons/settings.png';
import transfers from '../Assets/sideMenuIcons/transfer.svg';
import usd from '../Assets/sideMenuIcons/usd.png';
import profileImage from '../Assets/sideMenuIcons/portrait.png';
import verified from '../Assets/sideMenuIcons/verified.png';
import bank_card from '../Assets/sideMenuIcons/bank-card.png';
import piechart from '../Assets/sideMenuIcons/piechart.png';
import logoutIcon from '../Assets/sideMenuIcons/logout.png';
import { getKycListThunk, GetTransactionsListThunk, LogoutThunk } from "../../store/reducers/actions";
import { useAppDispatch, useAppSelector } from "../../store/store";
import CustomModal from "../ui/customModal/customModal";
import MyBtn from "../ui/MyBtn/MyBtn";

const SideMenu = ({ onItemClick,  activeMenuItem}) => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(store => store.userReducer.user.userData);
    const { userToken } = useAppSelector(store => store.userReducer.user);
    const isTokenValid = useAppSelector(state => state.userReducer.tokenIsValid);
    const kycStatus = useAppSelector(state => state.userReducer.user.flags.kycStatus);

    const [sidebarVisible, setSidebarVisible] = useState(window.innerWidth > 768);
    const sidebarRef = useRef(null);

    const toggleMenu = () => {
        setSidebarVisible(!sidebarVisible);
    };

    const closeMenu = useCallback(() => {
        if (sidebarVisible && window.innerWidth < 1400) {
            setSidebarVisible(false);
        }
    }, [sidebarVisible]);

    useEffect(() => {
        dispatch(getKycListThunk(userToken));
        dispatch(GetTransactionsListThunk(userToken));
    }, [dispatch, userToken]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                closeMenu();
            }
        };

        if (sidebarVisible) {
            document.addEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [sidebarVisible]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) {
                setSidebarVisible(true);
            } else {
                setSidebarVisible(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleLogout = () => {
        dispatch(LogoutThunk()).then(() => {
            window.location.href = "/login";
        });
    };

    const kycIsValid = () => {
        return kycStatus && kycStatus !== "New" && kycStatus !== "Rejected";
    };

    const userName = user.firstName ? `${user.firstName} ${user.lastName}` : user.companyName;

    const handleClick = menuItem => {
        onItemClick(menuItem);
        // Close sidebar when use click on sidebar-link || if page is smaller than 1400px
        if (window.innerWidth < 1400) {
            closeMenu();
        }
        // Scroll to the top of the page smoothly
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <aside className='side-menu-container' ref={sidebarRef}>

            <CustomModal isModalOpen={!isTokenValid} type={"confirm"}>
                <div className="title">Ошибка!</div>
                <div className="modal-section">Время вашей сессии истекло. Пожалуйста, войдите еще раз.</div>
                <div className="modal-section">
                    <MyBtn title={"Ok"} buttonType={"confirm"} onClick={handleLogout} />
                </div>
            </CustomModal>

            <div className="toggle-button" onClick={toggleMenu}>
                {sidebarVisible ? '✖' : '☰'}
            </div>

            <div className={`side-menu ${sidebarVisible ? 'show-side-menu' : 'hide-side-menu'}`}>
                <div className='sidebar-content' >

                    <div className="profile-container" >
                        <img src={profileImage} alt="Profile" className="profile-picture"/>
                        <div className="profile-details">
                            <div className="user-details">
                                <span className="user-name">{userName}</span>
                            </div>
                            <div className="verified-container">
                                 <span className="verified-text">
                                    {kycIsValid() ? 'Идентифицированный клиент' : 'Неидентифицированный клиент'}
                                </span>
                                {kycIsValid() && (
                                    <img src={verified} alt="Verified" className="verified-icon"/>
                                )}
                            </div>
                        </div>
                    </div>

                    <ul className="sidebar-list">
                        {!kycIsValid() ? (
                            <li className={`sidebar-link ${activeMenuItem === 'kyc' ? 'sidebar-active-link' : ''}`}
                                onClick={() => handleClick('kyc')}
                            >
                                <img src={bank_card} alt="Верификация" className='sidebar-icon'/>
                                <span className="sidebar-link-name">Верификация</span>
                            </li>
                        ) : (
                            <>
                                {/* bills link */}
                                <li
                                    className={`sidebar-link ${activeMenuItem === 'accounts' ? 'sidebar-active-link' : ''}`}
                                    onClick={() => handleClick('accounts')}>
                                    <img src={bank_card} alt="Счета" className='sidebar-icon'/>
                                    <span className="sidebar-link-name">Счета</span>
                                </li>
                                {/* translation */}
                                <li className={`sidebar-link ${activeMenuItem === 'transfer' ? 'sidebar-active-link' : ''}`}
                                    onClick={() => handleClick('transfer')}>
                                    <img src={transfers} alt="Переводы" className='sidebar-icon'/>
                                    <span className="sidebar-link-name">Переводы</span>
                                </li>
                                {/* conversion */}
                                <li className={`sidebar-link ${activeMenuItem === 'rate' ? 'sidebar-active-link' : ''}`}
                                    onClick={() => handleClick('rate')}>
                                    <img src={usd} alt="Конвертация" className='sidebar-icon'/>
                                    <span className="sidebar-link-name">Конвертация</span>
                                </li>
                                {/* crypto wallets */}
                                <li className={`sidebar-link ${activeMenuItem === 'crypto' ? 'sidebar-active-link' : ''}`}
                                    onClick={() => handleClick('crypto')}>
                                    <img src={btc} alt="Криптокошельки" className='sidebar-icon'/>
                                    <span className="sidebar-link-name">Криптокошельки</span>
                                </li>
                                {/* crypto translation */}
                                <li className={`sidebar-link ${activeMenuItem === 'cryptoTransfer' ? 'sidebar-active-link' : ''}`}
                                    onClick={() => handleClick('cryptoTransfer')}>
                                    <img src={piechart} alt="Криптопереводы" className='sidebar-icon'/>
                                    <span className="sidebar-link-name">Криптопереводы</span>
                                </li>
                            </>
                        )}
                    </ul>
                </div>

                <ul className='sidebar-bottom-content'>
                    {/* setting */}
                    <li className={`sidebar-link ${activeMenuItem === 'settings' ? 'sidebar-active-link' : ''}`}
                        onClick={() => handleClick('settings')}>
                        <img src={settings} alt="Настройки" className='sidebar-icon'/>
                        <span className="sidebar-link-name">Настройки</span>
                    </li>
                    {/* exit */}
                    <li className="sidebar-link" onClick={handleLogout}>
                        <img src={logoutIcon} alt="Выход" className='sidebar-icon'/>
                        <span className="sidebar-link-name">Выход</span>
                    </li>
                </ul>
            </div>
        </aside>
    );
};

export default SideMenu;

